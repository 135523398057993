<template>
  <div
    class="w-full md:flex md:items-start"
    :data-qa="'cart-product-' + item.listingId"
    data-test="cart-product"
  >
    <div class="flex grow items-start overflow-hidden">
      <RevLink
        v-if="props.item.image"
        :aria-label="item.title"
        target="_blank"
        :to="itemLink"
      >
        <RevIllustration
          :alt="item.title"
          class="h-60 w-60 max-w-60 cursor-pointer align-top md:h-[110px] md:w-[110px] md:max-w-[110px]"
          :height="60"
          :src="item.image"
          :width="60"
        />
      </RevLink>

      <div class="ml-12 inline-block md:ml-32">
        <span v-if="isOutOfStock" class="text-static-danger-hi body-2-bold">
          {{ i18n(translations.productOutOfStock) }}
        </span>
        <RevLink
          class="text-left"
          target="_blank"
          :to="itemLink"
          :underlined="false"
        >
          <div class="body-1-bold">{{ item.title }}</div>
        </RevLink>

        <div v-if="item.gradeName" class="flex">
          <p class="text-static-default-low body-2">
            {{ i18n(translations.productGrade) }}
          </p>
          <p class="text-static-default-low body-2 ml-4">
            {{ gradeName }}
          </p>
        </div>

        <AccessoriesSummary :accessories="item.providedAccessories" />

        <!-- TODO [PI-1677] Add isAccessory (boolean) to Cart API -->
        <p
          v-if="i18n(translations.crossSellCategory) === item.category"
          class="text-static-default-low body-2"
        >
          {{ i18n(translations.cartColor) }} {{ item.color }}
        </p>

        <div class="mb-8 flex md:my-8">
          <RevLink
            class="text-left"
            @click="openModal(MODAL_NAMES.CART_WARRANTY)"
          >
            <span class="text-static-default-low body-2-link">
              {{ i18n(translations.warranty) }}
            </span>
          </RevLink>
          <ClientOnly>
            <WarrantyModal :name="MODAL_NAMES.CART_WARRANTY" />
          </ClientOnly>
        </div>
        <div
          v-if="!isOutOfStock"
          :class="withDiscountedSwap ? 'heading-2' : 'body-1-bold'"
          data-qa="price"
        >
          {{ i18n.price(item.price) }}
        </div>
        <div
          v-if="
            !isOutOfStock &&
            shouldDisplayFormerPriceAndYouSave &&
            item.formerPrice
          "
        >
          <div class="mt-4 flex flex-row items-baseline space-x-4">
            <RevTag
              v-if="!withDiscountedSwap"
              class="caption-bold mt-2 whitespace-nowrap"
              :label="
                i18n(translations.youSaveDisplay, {
                  reduction: i18n.price(item.formerPrice - item.price),
                })
              "
              variant="success"
            />
            <span
              class="text-static-default-low"
              :class="
                withDiscountedSwap
                  ? 'body-2-striked'
                  : 'caption cursor-text whitespace-nowrap line-through'
              "
            >
              {{
                i18n(translations.originalPriceDisplay, {
                  price: i18n.price(item.formerPrice),
                })
              }}
            </span>
          </div>
        </div>

        <RevTag
          v-if="item.mobilePlan"
          class="mt-4"
          data-qa="mobile-plan-subsidy"
          :label="
            i18n(translations.bundlingDiscount, {
              price: i18n.price(item.mobilePlan.selectedOffer.subsidy),
            })
          "
          variant="primary"
        />

        <div v-if="!isOutOfStock && hasDeliveryPromise" class="mt-8">
          <div
            class="text-static-default-low body-2-bold whitespace-break-spaces"
          >
            <div
              v-if="
                item.options[0].choices.some((choice) => choice.price === 0)
              "
              data-test="shipping-promise"
            >
              {{
                i18n(
                  translations.shippingPromise,
                  getDeliveryDates({
                    choices: item.options[0].choices,
                  }),
                )
              }}
            </div>

            <div
              v-if="
                item.options[0].choices.some((choice) => choice.price !== 0)
              "
              data-test="shipping-promise-express"
            >
              {{
                i18n(
                  translations.shippingPromiseExpress,
                  getDeliveryDates({
                    choices: item.options[0].choices,
                    express: true,
                  }),
                )
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="my-20 flex justify-between md:my-0">
      <ArticleQuantitySelector
        v-if="!isOutOfStock"
        :id="`quantity-${item.listingId}`"
        :disabled="item.mobilePlan !== null"
        :limit="10"
        :listing-title="item.title"
        :max="item.quantityMax"
        :model-value="quantity"
        @update:model-value="
          (quantity: number) => emit('update-quantity', quantity)
        "
      />

      <RevButtonTiny
        class="ml-12"
        data-qa="delete-item-button"
        variant="secondaryDestructive"
        @click="emit('remove')"
      >
        {{ i18n(translations.productRemove) }}
      </RevButtonTiny>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { MarketCountryCode } from '@backmarket/http-api'
import type {
  CartItem,
  ShippingChoice,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'
import { RevTag } from '@ds/components/Tag'

import { MODAL_NAMES } from '~/scopes/checkout/config/constants'
import WarrantyModal from '~/scopes/reassurance/components/WarrantyModal/WarrantyModal.vue'

import translations from './Product.translations'
import AccessoriesSummary from './components/AccessoriesSummary/AccessoriesSummary.vue'
import ArticleQuantitySelector from './components/QuantitySelector/ArticleQuantitySelector.vue'

const props = defineProps<{
  item: CartItem
  quantity: number
  isOutOfStock: boolean
}>()

const emit = defineEmits<{
  (e: 'remove'): void
  (e: 'update-quantity', quantity: number): void
}>()

const experiments = useExperiments()

const {
  market: { countryCode },
} = useMarketplace()

const itemLink = computed(() => ({
  ...props.item.link,
  params: {
    locale: props.item.link?.params?.locale,
    slugV2: props.item.link?.params?.slugV2,
    uuid: props.item.link?.params?.uuid,
  },
}))

const i18n = useI18n()

const gradeName = computed(() => {
  if (props.item.gradeExtended.hasNewBattery) {
    return i18n(translations.productGradeWithNewBattery, {
      grade: props.item.gradeName,
    })
  }

  return props.item.gradeName
})

const shouldDisplayFormerPriceAndYouSave = computed(
  () =>
    ![MarketCountryCode.JP].includes(countryCode) &&
    props.item.marketplaceCategoryId === 2,
)

const hasDeliveryPromise = computed(
  () =>
    experiments['experiment.deliveryMethodPosition'] ===
    'shippingDeliveryMethod',
)

const getDeliveryDates = ({
  choices,
  express = false,
}: {
  choices: ShippingChoice[]
  express?: boolean
}) => {
  let earliest: Date | undefined
  let latest: Date | undefined
  let amount = ''

  choices
    .filter((choice) => (express ? choice.price !== 0 : choice.price === 0))
    .forEach((choice) => {
      if (choice.earliestArrivalDate) {
        const newDate = new Date(choice.earliestArrivalDate)
        earliest = earliest ?? newDate
        earliest = newDate < earliest ? newDate : earliest
      }

      if (choice.latestArrivalDate) {
        const newDate = new Date(choice.latestArrivalDate)
        latest = latest ?? newDate

        if (newDate >= latest) {
          latest = newDate
          amount = choice.priceWithCurrency
        }
      }
    })

  return {
    earliest: earliest
      ? i18n.date(earliest, { month: 'short', day: 'numeric' })
      : '',
    latest: latest ? i18n.date(latest, { month: 'short', day: 'numeric' }) : '',
    amount,
  }
}

const withDiscountedSwap = computed(() => {
  return experiments['experiment.ppDiscountedSwap'] === 'withDiscountedSwap'
})
</script>
